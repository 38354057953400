import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import history from 'globalHistory'
import { checkLoginStatus } from './Utils'
import { setAccounts, setLoadingAccounts } from 'redux/accounts'
import {
  setResources,
  setLoadingResources,
  invalidateResources,
} from 'redux/resources'
import {
  setWMLResources,
  setLoadingWMLResources,
  invalidateWMLResources,
} from 'redux/wmlResources'
import { setProfile } from 'redux/profile'

const AppSandbox = lazy(() => import('./App/AppSandbox'))
const App = lazy(() => import('./App/App'))
const Home = lazy(() => import('./Home/Home'))
const Projects = lazy(() => import('./Projects/Projects'))
const Training = lazy(() => import('./Training/Base'))
const Login = lazy(() => import('./Login/Login'))
const Homography = lazy(() => import('./App/App'))

const checkAuth = (user) => {
  //console.log(user)
  // check if the user and password matches the record
  const loadAccount = (tries) => {
    try {
      fetch(`/auth/login?email=${email}&pass=${password}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message == 'success') {
            window.user = data.user
            // authenticated successfully
            if (
              history.location.pathname == '/login' ||
              history.location.pathname == '/'
            ) {
              history.replace('/projects')
            }
          } else {
            localStorage.setItem('authenticatedUser', undefined)
            history.push('/')
          }
        })
        .catch((error) => {
          setTimeout(() => {
            loadAccount(tries + 1)
          }, 100)
        })
    } catch (e) {
      setTimeout(() => {
        loadAccount(tries + 1)
      }, 100)
    }
  }
  let email = ''
  let password = ''
  try {
    email = user.split(':')[0]
    password = user.split(':')[1]
    loadAccount(0)
  } catch (e) {
    localStorage.setItem('authenticatedUser', undefined)
    history.push('/')
  }
}

const authenticatedUser = () => {
  if (localStorage.getItem('authenticatedUser') == 'undefined') {
    // setAttemptedPage(history.location.pathname + history.location.search)
    if (
      history.location.pathname != '/' &&
      history.location.pathname != '/login'
    ) {
      history.replace('/')
    }
  } else {
    var user = localStorage.getItem('authenticatedUser')
    checkAuth(user)
  }
}

const useAccount = (dispatch) => {
  /*
    const loadAccounts = useCallback(
      (tries = 0) => {
        fetch('/api/accounts')
          .then(res => res.json())
          .then(accounts => {
            dispatch(setAccounts(accounts))
            dispatch(setLoadingAccounts(false))
            if (accounts.length === 0 && tries < 300) {
              setTimeout(() => {
                loadAccounts(tries + 1)
              }, 10000)
            }
          })
          .catch(error => {
            console.error(error)
          })
      }, [dispatch]
    )
  */

  useEffect(() => {
    dispatch(setLoadingAccounts(false))
    //loadAccounts()
  }, [dispatch])
}

const useUpgradeToken = (account) => {
  /*
  const [tokenUpgraded, setTokenUpgraded] = useState(false)
  useEffect(() => {
    setTokenUpgraded(false)
    if (account) {
      fetch(`/api/upgrade-token?account=${account}`)
        .then(() => {
          setTokenUpgraded(account)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [account])

  return tokenUpgraded
  */
}

const recursivelyFetchResources = async (url, oldResources) => {
  if (url) {
    const trimmed = url.replace(/^\/v2\/resource_instances/, '')
    const json = await fetch(`/api/cos-instances${trimmed}`).then((r) =>
      r.json()
    )
    const { next_url, resources } = json
    return recursivelyFetchResources(next_url, [...oldResources, ...resources])
  }
  return oldResources
}

const useResourceList = (dispatch) => {
  useEffect(() => {
    let isCancelled = false
    const loadResources = (tries = 0) => {
      console.log('loading new projects')

      if (window.user) {
        fetch(`/api/projects?user=${window.user.email}`)
          .then((res) => res.json())
          //.then(json => recursivelyFetchResources(json.next_url, json.resources))
          .then((allResources) => {
            // if (!isCancelled) {
            // Alphabetize the list by name.
            allResources.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            //console.log('projects', allResources);
            //window.projects = projects;
            dispatch(setResources(allResources))
            dispatch(setLoadingResources(false))
            if (allResources.length === 0 && tries < 30) {
              setTimeout(() => {
                loadResources(tries + 1)
              }, 10)
            }
            // }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        setTimeout(() => {
          loadResources(tries + 1)
        }, 10)
      }
    }

    loadResources()
  }, [dispatch])
}

const recursivelyFetchWMLResources = async (url, oldResources) => {
  if (url) {
    const trimmed = url.replace(/^\/v2\/resource_instances/, '')
    const json = await fetch(`/api/wml-instances${trimmed}`).then((r) =>
      r.json()
    )
    const { next_url, resources } = json
    return recursivelyFetchWMLResources(next_url, [
      ...oldResources,
      ...resources,
    ])
  }
  return oldResources
}

const useWMLResourceList = (dispatch, tokenUpgraded) => {
  const loadWMLResources = useCallback(
    (tries = 0) => {
      fetch('/api/wml-instances')
        .then((res) => res.json())
        .then((allResources) => {
          //   //recursivelyFetchWMLResources(json.next_url, json.resources)
          // )
          // .then(allResources => {

          // Alphabetize the list by name.
          allResources.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )

          dispatch(setWMLResources(allResources))
          dispatch(setLoadingWMLResources(false))
          if (allResources.length === 0 && tries < 300) {
            setTimeout(() => {
              loadWMLResources(tries + 1)
            }, 10000)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    [dispatch]
  )

  useEffect(() => {
    if (tokenUpgraded) {
      dispatch(invalidateWMLResources(true))
      loadWMLResources()
    }
  }, [dispatch, loadWMLResources, tokenUpgraded])
}

const useProfile = (dispatch, account) => {
  useEffect(() => {
    if (account) {
      /*
      fetch('/auth/userinfo')
        .then(res => res.text())
        .then(userId => fetch(`/api/accounts/${account}/users/${userId}`))
        .then(res => res.json())
        .then(user => {
          console.log(user);
          dispatch(setProfile(user))
        })
        .catch(error => {
          console.error(error)
        })
        */
    }
  }, [account, dispatch])
}

const Routing = ({ dispatch, activeAccount }) => {
  const attemptedPage = authenticatedUser()

  useAccount(dispatch)
  useEffect(() => {
    if (activeAccount) {
      dispatch(invalidateResources())
    }
  }, [activeAccount, dispatch])
  const tokenUpgraded = useUpgradeToken(activeAccount)
  useResourceList(dispatch)
  //useWMLResourceList(dispatch, tokenUpgraded)
  //useProfile(dispatch, activeAccount)

  return (
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              // <Home {...props} attemptedPage={attemptedPage} />
              <Login {...props} attemptedPage={attemptedPage} />
            )}
          />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/sandbox" component={AppSandbox} />
          <Route exact path="/training" component={Training} />
          <Route
            exact
            path="/login"
            render={(props) => (
              <Login {...props} attemptedPage={attemptedPage} />
            )}
          />
          <Route exact path="/projects/:projectid" component={App} />
          <Route
            path="/:projectid"
            component={({
              match: {
                params: { projectid },
              },
              location: { search },
            }) => <Redirect to={`/projects/${projectid}${search}`} />}
          ></Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  activeAccount: state.accounts.activeAccount,
})
export default connect(mapStateToProps)(Routing)
