// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Move_wrapper__3vCxE {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.Move_move__l1bRx {\n  position: absolute;\n  border: 1px solid white;\n  left: 0px;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "Move_wrapper__3vCxE",
	"move": "Move_move__l1bRx"
};
module.exports = exports;
