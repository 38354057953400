// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Prediction_wrapper__1bB5h {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.Prediction_label__1sq4f {\n  position: absolute;\n  background-color: var(--blue);\n  font-weight: 500;\n  font-size: 12px;\n  color: #ffffff;\n  padding: 0 6px;\n  left: -3px;\n  top: -24px;\n  height: 18px;\n  line-height: 14px;\n  border: 1px solid black;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "Prediction_wrapper__1bB5h",
	"label": "Prediction_label__1sq4f"
};
module.exports = exports;
