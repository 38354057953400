// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Box2_wrapper__2i3O- {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.Box2_fill__2l11L {\n  position: absolute;\n  background-color: white;\n  opacity: 0.1;\n  left: 1px;\n  top: 1px;\n  right: 1px;\n  bottom: 1px;\n}\n\n.Box2_wrapperHover__29Mx5 {\n}\n\n.Box2_wrapperHover__29Mx5 .Box2_fill__2l11L {\n  opacity: 0.6;\n}\n\n.Box2_draw__20Jm3 {\n  position: absolute;\n  border: 1px solid white;\n  left: -2px;\n  top: -2px;\n  right: -2px;\n  bottom: -2px;\n}\n\n.Box2_inline__3DRIo {\n  position: absolute;\n  border: 0px solid #00000069;\n  left: 0px;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n}\n\n.Box2_outline__3r5Ao {\n  position: absolute;\n  border: 1px solid #00000069;\n  left: -3px;\n  top: -3px;\n  right: -3px;\n  bottom: -3px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "Box2_wrapper__2i3O-",
	"fill": "Box2_fill__2l11L",
	"wrapperHover": "Box2_wrapperHover__29Mx5 Box2_wrapper__2i3O-",
	"draw": "Box2_draw__20Jm3",
	"inline": "Box2_inline__3DRIo",
	"outline": "Box2_outline__3r5Ao"
};
module.exports = exports;
