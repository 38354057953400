// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TouchTargets_wrapper__3Q7Uf {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.TouchTargets_nobBase__13jBZ {\n  position: absolute;\n  border: 1px solid rgba(0, 0, 0, 0.06);\n  width: 9px;\n  height: 9px;\n  z-index: 10;\n}\n\n.TouchTargets_nobBase__13jBZ::before {\n  content: '';\n  position: absolute;\n  background-color: rgba(0, 0, 0, 0.06);\n  left: 1px;\n  top: 1px;\n  width: 5px;\n  height: 5px;\n}\n\n.TouchTargets_topLeft__25VU5 {\n  top: -4px;\n  left: -4px;\n}\n\n.TouchTargets_topRight__-llqs {\n  top: -4px;\n  right: -4px;\n}\n\n.TouchTargets_bottomRight__3dSkl {\n  bottom: -4px;\n  right: -4px;\n}\n\n.TouchTargets_bottomLeft__2zeC6 {\n  bottom: -4px;\n  left: -4px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "TouchTargets_wrapper__3Q7Uf",
	"nobBase": "TouchTargets_nobBase__13jBZ",
	"topLeft": "TouchTargets_topLeft__25VU5 TouchTargets_nobBase__13jBZ",
	"topRight": "TouchTargets_topRight__-llqs TouchTargets_nobBase__13jBZ",
	"bottomRight": "TouchTargets_bottomRight__3dSkl TouchTargets_nobBase__13jBZ",
	"bottomLeft": "TouchTargets_bottomLeft__2zeC6 TouchTargets_nobBase__13jBZ"
};
module.exports = exports;
