// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".View_wrapper__1TiC7 {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.View_fill__1ogKD {\n  position: absolute;\n  background-color: white;\n  opacity: 0.1;\n  left: 1px;\n  top: 1px;\n  right: 1px;\n  bottom: 1px;\n}\n\n.View_wrapperHover__1IDhV {\n}\n\n.View_wrapperHover__1IDhV .View_fill__1ogKD {\n  opacity: 0.6;\n}\n\n.View_draw__2mqjo {\n  position: absolute;\n  border: 2px solid white;\n  left: -2px;\n  top: -2px;\n  right: -2px;\n  bottom: -2px;\n}\n\n.View_inline__6LLCD {\n  position: absolute;\n  border: 1px solid #000000;\n  left: 0px;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n}\n\n.View_outline__1_ucS {\n  position: absolute;\n  border: 1px solid #000000;\n  left: -3px;\n  top: -3px;\n  right: -3px;\n  bottom: -3px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "View_wrapper__1TiC7",
	"fill": "View_fill__1ogKD",
	"wrapperHover": "View_wrapperHover__1IDhV View_wrapper__1TiC7",
	"draw": "View_draw__2mqjo",
	"inline": "View_inline__6LLCD",
	"outline": "View_outline__1_ucS"
};
module.exports = exports;
