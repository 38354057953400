// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Nobs_wrapper__1UWvU {\n  position: absolute;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.Nobs_nobBase__1PIp7 {\n  position: absolute;\n  border: 1px solid white;\n  width: 7px;\n  height: 7px;\n}\n\n.Nobs_topLeft__16vS2 {\n  top: -3px;\n  left: -3px;\n}\n\n.Nobs_topRight__1Zpj3 {\n  top: -3px;\n  right: -3px;\n}\n\n.Nobs_bottomRight__1w-hB {\n  bottom: -3px;\n  right: -3px;\n}\n\n.Nobs_bottomLeft__3k9Wm {\n  bottom: -3px;\n  left: -3px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "Nobs_wrapper__1UWvU",
	"nobBase": "Nobs_nobBase__1PIp7",
	"topLeft": "Nobs_topLeft__16vS2 Nobs_nobBase__1PIp7",
	"topRight": "Nobs_topRight__1Zpj3 Nobs_nobBase__1PIp7",
	"bottomRight": "Nobs_bottomRight__1w-hB Nobs_nobBase__1PIp7",
	"bottomLeft": "Nobs_bottomLeft__3k9Wm Nobs_nobBase__1PIp7"
};
module.exports = exports;
